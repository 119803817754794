/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.transparent {
  background: transparent !important;
  --background: transparent !important;
}

video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/font/Poppins-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/font/Poppins-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/font/Poppins-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/font/Poppins-Bold.woff2) format('woff2');
}

:root {
  --vh-100: 100vh;
  --room-padding: 0.75rem;
  --room-mini-video-height: 7rem;


  // Room Presentation Video Width
  --room-sidebar-width: 23.375rem;
  --room-mini-video-width: 12rem;
  --room-mini-video-container-width: calc(var(--room-mini-video-width) + 2 * var(--room-padding));

  // Default values for desktop and mobile layouts widths
  --room-desktop-presentation-video-width: calc(100vw - var(--room-mini-video-container-width) - var(--room-padding));
  --room-mobile-presentation-video-width: 100%;

  // Current presentation video width
  // Changing this value will change the actual video width
  --room-presentation-video-width: var(--room-desktop-presentation-video-width);

  // Room Presentation Video Height
  --room-mobile-header-height: 3.5rem;
  --room-mobile-mini-video-height: var(--room-mini-video-height);
  --room-mobile-mini-video-margin-top: var(--room-padding);
  --room-mobile-mini-video-margin-bottom: var(--room-padding);
  --room-mobile-header-bottom-padding: var(--room-padding);

  --room-footer-height: 4.125rem;

  // Default values for desktop and mobile layout heights
  --room-desktop-presentation-video-height: 100%;
  --room-desktop-presentation-video-height-fixed: calc(var(--vh-100) - var(--room-padding) - var(--room-footer-height));
  --room-mobile-presentation-video-height: calc(
    var(--vh-100) -
    var(--room-mobile-header-height) -
    var(--room-mobile-header-bottom-padding) -
    var(--room-mobile-mini-video-height) -
    var(--room-mobile-mini-video-margin-top) -
    var(--room-mobile-mini-video-margin-bottom) -
    var(--room-footer-height)
  );

  // Default height values
  --room-presentation-video-height: var(--room-desktop-presentation-video-height);
  --room-presentation-video-height-fixed: var(--room-desktop-presentation-video-height-fixed);
}

:root {
  --white: #fff;
  --primary: #93b7ff;
  --bg-color: #f3f4f8;
  --green_color: #00ff5a;
  --gray: #D5D5D5;
  --transparent: rgba(0, 0, 0, 0);
  --text-dark: #BDBDBE;
  --text-black: #444444;
  --text-light: #C6CEE0;
  --text-light2: #C9C9C9;
}

.bg_color {
  --background: var(--bg-color);
}

.d-flex {
  display: flex;
  align-items: center;
  width: 100%;


  .end {
    margin-left: auto;
    margin-right: 0;
    display: block;
  }
}

.send .text a {
  color: var(--white) !important;
}

// Fixes ionic dropdowns on Chrome 114
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

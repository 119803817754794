* {
  font-family: Poppins;
  font-style: normal;
}

ion-modal div {
  --border-radius: 0.75rem;

  @media(max-width: 768px) {
    --width: 100%;
    --height: 100%;
    --border-radius: none !important;
  }
}

.contact-Submenu-PopOver {
  --width: fit-content;
  --min-width: 15.625rem;

  @media(max-width: 300px) {
    --max-width: 17rem;
  }
}

.header-md::after {
  background-image: none;
  box-shadow: none;
  border: none !important;
}

.contacts-item {
  .item-native .item-inner {
    padding-right: 0px !important;
    padding-inline-end: 0px !important;
  }
}

ion-searchbar {
  .searchbar-input-container {
    height: 3rem !important;

    .searchbar-input {
      font-size: 0.813rem !important;
      padding-inline-start: 3.25rem !important;
      box-shadow: none;
    }

    .searchbar-search-icon {
      left: 1rem !important;
    }

    .searchbar-clear-button {
      right: 0.7rem !important;
      top: -0.188rem !important;
    }
  }

}

ion-header {
  position: relative;
  z-index: 1;
  backdrop-filter: blur(0px) !important;
}

.edit-org-modal {
  --width: 525px;
  --height: 466px;
  --border-radius: 0.5rem;
}

.invite-org-contact-modal {
  --width: 34rem;
  --height: 32rem;
  --backdrop-opacity: 1 !important;

  @media(max-width: 1000px) {
    --width: 34rem;
    --height: 32rem;
  }

  @media(max-width: 810px) {
    --width: 100%;
    --height: 100%;
    --border-radius: none !important;
  }
}

.reset-password-modal {
  --width: 26rem;
  --height: 29.5625rem;
}

.billing-modal {
  --width: 61rem;
  --height: 38.625rem;
  --backdrop-opacity: 1 !important;

  @media(max-width: 1000px) {
    --width: 50.625rem;
    --height: 38.625rem;
  }

  @media(max-width: 810px) {
    --width: 100%;
    --height: 100%;
    --border-radius: none !important;
  }
}

.delete-alert-container {
  --height: 11.438rem;
  --width: 17.5rem;
  --background: var(--ion-color-light);

  .alert-title {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--ion-color-dark-grey);
  }

  .alert-sub-title {
    color: var(--ion-color-medium-grey);
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 0.625rem;
  }

  .alert-message {
    color: var(--ion-color-medium-grey);
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .alert-button-group {
    margin-top: 0.625rem;
  }

  .alert-button {
    height: 1.75rem;
    padding: auto 1rem;
    box-shadow: none !important;
    font-weight: 600;
    font-size: 0.813rem;
    text-transform: capitalize;
    color: var(--ion-color-primary);
  }

  .alert-button:last-child {
    border-radius: 1.75rem;
    color: var(--ion-color-light);
    background: var(--ion-color-primary);
  }

}

.stop-call-alert-container {
  @extend .delete-alert-container;
  --height: auto;

  .alert-button-group {
    margin-bottom: 0.625rem;
  }

  .alert-button {
    text-transform: none;
  }
}

.show-mobile-alert-container {
  @extend .delete-alert-container;
  --height: auto;
  align-items: flex-end;
  padding-bottom: 1rem;

  .alert-button-group {
    margin-bottom: 0.625rem;
  }

  .alert-button {
    text-transform: none;
  }

  .alert-checkbox-group {
    top: 3.5rem;
    border: none;
  }

  .alert-checkbox-label {
    color: var(--ion-color-medium-grey);
    font-weight: normal;
    font-size: 0.875rem;
    -webkit-padding-start: 43px;
    padding-inline-start: 43px;
  }

  .alert-button-group {
   /* top: -3rem; */
    position: relative
  }
}

.show-mobile-alert-container-dplnk .alert-wrapper{
  min-width: 20rem;
}

.share-link-modal {
  --width: 375px;
  --height: 340px;
}

.streaming-configs-modal {
  --width: 450px;
  --height: 325px;
}

.call-configs-modal {
  --width: 29.6875rem;
  --height: 24rem;

  h1 {
    color: var(--ion-color-dark-grey);
    font-weight: 600;
    font-size: 1.25rem;
    margin-top: 1rem;
  }

  @media(max-width: 768px) {
    .ion-page {
      justify-content: initial;
    }
  }
}

.room-settings-modal {
  --width: 29.6875rem;
  --height: 30.5rem;
  --backdrop-opacity: 1 !important;
}

.profile-page-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: none !important;
}

.organization-add-member-modal {
  --width: 475px;
  --height: 375px;
}

.hide-popover-overFlow {
  --width: fit-content;
  --min-width: 15.625rem;
  position: absolute;
  left: -146px;

  @media(min-width: 577px) and (max-width: 715px) {
    --min-width: 13rem;
    left: -138px;
  }

  @media(max-width: 576px) {
    left: 0px;
  }
}

.hide-popover-overFlow .hydrated {
  overflow: hidden !important;
  --overflow: hidden !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0);

  .list-md {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}

ion-select-popover ion-item,
ion-list ion-item:not(.register-list) {
  --border-style: none !important;
}

.hide-popover-overFlow .lang-text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: var(--ion-color-dark-grey) !important;
}

.lang-text.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  opacity: 1;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1rem;
  letter-spacing: 0.4px;
}

ion-radio {
  --color: transparent;
  --color-checked: transparent;
}

.item-radio-checked {
  --background: rgb(56, 58, 62, 0.2) !important;
}

.hidden-participants-warning-modal-en {
  --width: 640px;
  --height: 190px;

  .modal-wrapper {
    --background: rgba(0, 0, 0, 0.80);
    backdrop-filter: blur(6px);
  }
}

.hidden-participants-warning-modal-fr {
  --width: 640px;
  --height: 216px;

  .modal-wrapper {
    --background: rgba(0, 0, 0, 0.80);
    backdrop-filter: blur(6px);
  }
}

.recording-modal {
  --width: 450px;
  --height: auto;

  &.bottom {
    align-items: flex-end;
  }

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

.limit-modal {
  --width: 450px;
  --height: 220px;
}

.no-hover {
  --background-hover: transparent;
}
.environmental-report-modal {
  --width: 29.688rem;
  --height: 33.50rem;
  --border-radius: 0.5rem;

  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

.guest-environmental-report-modal {
  @extend .environmental-report-modal;
  --height: 37.50rem;
  background: var(--ion-color-dark);
}

.searchbar-container {
  ion-searchbar {
    .searchbar-input-container {
      .searchbar-input {
        --border-radius: 2rem;
      }
    }
  }
}

.messaging-modal {
  margin: 0 0.5rem auto 0.5rem;
}

.tool-tip {
    position: relative;
  }
  
  .tool-tip::before,
  .tool-tip::after {
    --scale: 0;
    --arrow-size:5px;
    --tooltip-color: #333;
    box-sizing: border-box;
    position: absolute;
    top: -.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;

    @media(max-width: 576px){
      display: none !important;
    }
    @media(max-width: 810px) and (orientation:landscape){
      display: none !important;
    }
  }
  
  .tool-tip::before {
    --translate-y: calc(-100% - var(--arrow-size));
    box-sizing: border-box;
    content: attr(data-tooltip);
    color: white;
    font-size: 0.625rem;
    font-weight: 400 !important;
    padding: 0.388rem 0.4rem !important;
    border-radius: .25rem;
    text-align: center;
    width: max-content;
    background: var(--ion-color-dark-tint) !important;
    z-index: 1000;
  }
  
  .tool-tip:hover::before,
  .tool-tip:hover::after {
    --scale: 1;
  }
  
  .tool-tip::after {
    --translate-y: calc(-1.3 * var(--arrow-size));
  
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color:var(--ion-color-dark-tint) !important;
    transform-origin: top center;

  }
//------------------
  .bottom-tool-tip {
    position: relative;
  }
  
  .bottom-tool-tip::before,
  .bottom-tool-tip::after {
    --scale: 0;
    --arrow-size: 5px;
    --tooltip-color: #333;
    box-sizing: border-box;
    position: absolute;
    top: 3.713rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: top center;

    @media(max-width: 576px) {
      display: none !important;
    }
    @media(max-width: 810px) and (orientation:landscape){
      display: none !important;
    }
  }
  
  .bottom-tool-tip::before {
    --translate-y: calc(-110% - var(--arrow-size));
    box-sizing: border-box;
    content: attr(data-tooltip);
    color: white;
    font-size: 0.625rem;
    font-weight: 400 !important;
    padding: 0.188rem 0.375rem;
    border-radius: .25rem;
    text-align: center;
    width: max-content;
    // max-width: 100%;
    background: var(--ion-color-dark-tint);
    z-index: 1000;
  }
  
  .bottom-tool-tip:hover::before,
  .bottom-tool-tip:hover::after {
    --scale: 1;
  }
  
  .bottom-tool-tip::after {
    --translate-y: calc(-7.4 * var(--arrow-size));
  
    content: '';
    border: var(--arrow-size) solid transparent;
    border-bottom-color: var(--ion-color-dark-tint);
    transform-origin: bottom center;
  }

  .profile-setting-left-space::before,
  .profile-setting-left-space::after {
    left: 30% !important;
  }

  .account-setting-left-space::before,
  .account-setting-left-space::after {
    left: -1.25rem !important;
    top: 3.75rem;
  }

  .account-setting-left-space:hover::after {
    transform: translateX(2rem) translateY(-2.3438rem) scale(1);
  }

  .language-top-space::before,
  .language-top-space::after {
    top: 4.3125rem !important;
  }

  .upgrade-top-space::before,
  .upgrade-top-space::after {
    top: 3.5625rem !important;
  }

  .recording-play-left-space::before,
  .recording-play-left-space::before {
    left: -2.4rem;
  }

  .recording-play-left-space-en::before,
  .recording-play-left-space-en::before {
    left: -1.5rem;
  }

  .recording-more-left-space::before,
  .recording-more-left-space::before {
    left: -1.4rem;
  }

  .room-tooltip::before {
    background-color: var(--ion-color-primary-shade);
  }

  .room-tooltip::after {
    border-top-color: var(--ion-color-primary-shade);
    z-index: 1000 !important;
  }

  .room-button-left-space::before,
  .room-button-left-space::after {
    left: -2.8rem; 
  }

  .room-button-left-space:hover::after {
    transform: translateX(3.8rem) translateY(-5.5px) scale(1);
  }

  .room-button-left-space-chat-en::before,
  .room-button-left-space-chat-en::after {
    left: -1.7rem;
  }

  .room-button-left-space-chat-en:hover::after {
    transform: translateX(2.5rem) translateY(-5.9px) scale(1);
  }

  .room-button-left-space-chat::before,
  .room-button-left-space-chat::after {
    left: -3.7rem;
  }

  .room-button-left-space-chat:hover::after {
    transform: translateX(4.1rem) translateY(-5.9px) scale(1);
  }

  .room-button-left-space-people-en::before,
  .room-button-left-space-help::after {
    left: -.5rem;
  }

  .room-button-left-space-people-en:hover::after {
    transform: translateX(0rem) translateY(-5.9px) scale(1);
  }

  .getHelp-icon::before,
  .getHelp-icon::after {
   left: -.5rem;
  }

  .getHelp-icon:hover::after {
    transform: translateX(1.3rem) translateY(-5.9px) scale(1);
  }

  .recording-play-left-space:hover::after{
    transform: translateX(-0.6rem) translateY(-6.2px) scale(1);
  }


* {
    font-family: Poppins;
    font-style: normal;
}

ion-button {
    text-transform: inherit;
    --box-shadow: none;
    cursor: pointer;
}

.button-solid {
    --border-radius: 50px !important;
    --padding-start: 1.5rem !important;
    --padding-end: 1.5rem !important;
}

.room-settings {
    @extend .button-solid;
    --padding-start: 0rem !important;
    --padding-end: 0rem !important;
    --ripple-color: transparent !important;
}

.button-outline {
    --border-radius: 50px;
    --padding-start: 1.5rem;
    --padding-end: 1.5rem;
}

.config-options,
.config-options-left {
    --max-width: 360px;
    --min-width: 360px;

    @media(max-width: 360px) {
        --max-width: 280px;
        --min-width: 280px;
    }
}

.config-options-left {
    left: -110px;

    @media(max-width: 576px) {
        left: 0;
    }
}

.copiedToast {
    --width: 80px;
    --background: #7FC786;
    top: -150px;
    text-align: center;

    @media(max-width: 500px) {
        top: -120px;
    }
}

.incorrectPasscodeToast {
    --width: 325px;
    --background: #C56028;
    top: -150px;
    text-align: center;

    @media(max-width: 330px) {
        top: -120px;
        --width: 280px;
        font-size: 12PX;
    }

    @media(max-width: 300px) {
        --width: 260px;
    }
}

.mic-off-toast {
    --max-width: max-content !important;
}

.controlsPopOver {
    position: absolute;
    left: -64px;
    top: -40px;
    --width: fit-content;
    --min-width: 15.625rem;
    --background: #fff;

    @media(max-width: 330px) {
        left: 0px;
        top: 0px;
    }

    @media(max-width: 300px) {
        --max-width: 17rem;
    }
}

.participantsControlsPopOver {
    --width: fit-content;
    --min-width: 15.625rem;
    position: absolute;
    left: -40px;
    --background: #fff;

    @media(max-width: 330px) {
        left: 0px;
        top: 0px;
        --max-width: 17rem;
    }
}

.accountSettingsPopOver {
    --background: var(--ion-color-primary-contrast);
    --min-width: 23.4375rem;
    border-radius: 1.25rem;
    top: 1rem;

    @media(max-width: 400px) {
        --min-width: unset;
        --width: 95vw;
    }
}

.accountSettingsPopOver .popover-wrapper .popover-content.sc-ion-popover-md {
    border-radius: 0.5rem !important;
}

.callerModalSize {

  .modal-wrapper {
    width: 100vw !important;
    height: 100% !important;
    border-radius: 0.5rem;
    background-color: var(--ion-backdrop-color) !important;
  }

}

.caller-modal-size {
  --width: 23.4375rem;
  --height: 25rem;
  --border-radius: 0.5rem;

    .modal-wrapper {
        width: 100vw;
        height: 100%;
        border-radius: 0.5rem;
        background-color: var(--ion-backdrop-color) !important;
    }

    @media(max-width: 576px) {
      --width: 100vw;
      --height: 100%;
      border-radius: 0px;
    }

}

.input-error-container {
    height: fit-content;

    .error-message {
        color: var(--ion-color-danger);
        font-family: Poppins;
        font-size: 0.78rem;
        font-style: normal;
        font-weight: 400;
        margin-left: 1rem;
    }
}

.register-list {
    --background: var(--ion-color-primary-contrast);
    margin-top: 0.78rem;
    --highlight-color-valid: var(--ion-color-primary);
    color: var(--ion-color-dark-grey);
    --inner-padding-end: 0px;

    .label {
        font-family: Poppins;
        font-style: normal;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;
    }

    .inputField {
        font-family: Poppins;
        font-style: normal;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;

        .input-clear-icon.sc-ion-input-ios {
            background-image: url('../assets/image/025-clear-input.svg') !important;
            margin-top: 0px;
            margin-right: 0.75rem;
        }

        .input-clear-icon.sc-ion-input-md {
            background-image: url('../assets/image/025-clear-input.svg') !important;
            margin-top: 0px;
            margin-right: 12px;
        }
    }

    .icon {
        color: var(--ion-color-medium-grey);
        font-size: 1.375rem;
        position: absolute;
        top: 1.1875rem;
        right: 1rem;
        z-index: 100;
        cursor: pointer;
    }

    @media(max-width: 500px) {
        --inner-padding-start: 0px;
    }

}

.register-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.edit-organization-button {
    justify-content: end !important;
}

.register-button {
    --box-shadow: none;
    text-transform: inherit;
    height: 2.25rem;
    --padding-start: 1.1875rem !important;
    --padding-end: 1.1875rem !important;
    border-radius: 1.125rem;
    font-size: 0.875rem;
    font-weight: 600;
    min-width: 12rem;

    @media(max-width: 500px) {
        // width: 10.188rem;
        width: fit-content;
        height: 2.25rem;
    }
}

.register-terms {
    font-size: 0.875rem;
    margin-top: 0.6rem;
    font-weight: 400;
    text-align: center;
    color: var(--ion-color-medium-grey);

    @media(max-width: 500px) {
        margin-top: 1.6rem;
    }
}

.language-container {
    width: 100%;
    text-align: center;
    padding: 2rem auto;
    position: absolute;
    bottom: -5rem;

    .language-text {
        color: var(--ion-color-primary);
        cursor: pointer;
    }

    @media(max-width: 500px) {
        bottom: -6rem;
    }

    @media(max-height: 770px) {
        bottom: -3.5rem;
    }

    @media(max-height: 290px) {
        bottom: -2.5rem;
    }

}

.action-sheet-controller {
    --background: var(--ion-color-dark-green);
    opacity: 1;
    --button-color: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary);

    .action-sheet-group:first-child {
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
        padding-top: 0.5rem !important;
        border: none !important;
    }

    .action-sheet-group:last-child {
        padding-bottom: 0.313rem !important;
        border: none !important;
    }
}

.recording-action-controller {
    --background: var(--ion-color-primary-contrast);
    opacity: 1;
    --button-color: var(--ion-color-dark-grey);
    --color: var(--ion-color-primary);

    .action-sheet-group:first-child {
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
        padding-top: 0.5rem !important;
        border: none !important;
    }

    .action-sheet-group:last-child {
        padding-bottom: 0.313rem !important;
        border: none !important;
    }
}

.card-header {
    .in-toolbar {
        display: flex;
        align-items: center;
    }
}

.report-content-text > b {
    color: var(--ion-color-dark-grey);
}

.heading-text > b {
    font-size: 2rem;
}
